import React from 'react'
import rehypeReact from 'rehype-react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { H2, H3, H4, H5, H6, H7, P, Blockquote, Figcaption } from './typography'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    // Shift all headings down one
    h1: H2,
    h2: H3,
    h3: H4,
    h4: H5,
    h5: H6,
    h6: H7,
    p: P,
    blockquote: Blockquote,
    figcaption: Figcaption
  },
}).Compiler

const BlogPostContent = ({ content, className }) => (
  <div className={className}>{renderAst(content)}</div>
)

export default styled(BlogPostContent)`
  ${tw`text-lg`}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`mb-2`}
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    ${tw`mt-6`}
  }

  img,
  .gatsby-resp-image-wrapper {
    ${tw`my-8`}
  }

  a {
    ${tw`underline`}
  }

  ol,
  ul {
    ${tw`text-base mb-6 ml-6`}
  }

  ol {
    ${tw`list-decimal`}
  }

  ul {
    ${tw`list-disc`}
  }

  .gatsby-highlight {
    pre {
      ${tw`bg-blue-100`}

      code {
        ${tw`text-sm whitespace-pre-wrap`}
      }
    }
  }
`
