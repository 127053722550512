import React from 'react'
import { Link, graphql } from 'gatsby'

import Image from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Heading, Small } from '../components/typography'
import PostContent from '../components/post-content'

const PostTemplate = ({ data, location, pageContext }) => {
  const { post } = data
  const { type, previous, next } = pageContext
  const { title, description, date, dates } = post.frontmatter
  const coverImage = data.coverImage

  return (
    <Layout location={location} title={type} titleLink={`/${type}`}>
      <SEO
        title={title}
        suffix={type}
        description={description || post.excerpt}
      />
      <div className="max-w-3xl mx-auto text-center py-4">
        <p className="font-body font-bold text-xs text-grey-700 uppercase tracking-widest -mb-2">
          {date ? date : dates ? dates : ''}
        </p>
        <Heading level="1">{title}</Heading>
      </div>
      <Image
        fluid={coverImage.childImageSharp.fluid}
        alt={""}
      />
      <div className="max-w-3xl mx-auto px-4 py-12">
        <PostContent content={post.htmlAst} className="mb-12" />

        <ul className="sm:flex sm:justify-between">
          <li className="sm:w-1/2 mb-6 sm:mb-0">
            {previous && (
              <div>
                <Link to={previous.fields.slug} rel="prev">
                  <Heading level="5">&larr; {previous.frontmatter.title}</Heading>
                </Link>
                <Small>{previous.frontmatter.description}</Small>
              </div>
            )}
          </li>
          <li className="sm:w-1/2">
            {next && (
              <div>
                <Link to={next.fields.slug} rel="next">
                  <Heading level="5">{next.frontmatter.title} &rarr;</Heading>
                </Link>
                <Small>{next.frontmatter.description}</Small>
              </div>
            )}
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query ($id: String!, $coverImageRegex: String!) {
    post: markdownRemark(id: { eq: $id } ) {
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        dates
        description
      }
    }
    coverImage: file(absolutePath: { regex: $coverImageRegex }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
